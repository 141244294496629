@import "assets/styles/main.scss";

.success {
  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__title {
    margin: 0;
    flex: 0 0 auto;
    font-family: inherit;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: #3e414d;
  }

  &__text {
    margin: 12px 0 20px;
    font-family: inherit;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.02em;
    color: rgba(62, 65, 77, 0.6);
  }

  &__btn-wrapper {
    align-self: flex-end;
  }
}
