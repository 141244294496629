@import "assets/styles/main.scss";

.modal-info-program {
  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__title {
    margin: 0;
    font-family: inherit;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: #3e414d;
  }

  &__block {
    margin-top: 40px;
  }

  &__block-title {
    margin: 0 0 12px;
    font-family: inherit;
    font-style: normal;
    font-weight: 700;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.02em;
    color: #3e414d;
  }

  &__info-title {
    margin: 0 0 4px;
    font-family: inherit;
    font-style: normal;
    font-weight: 400;
    font-size: 12px;
    line-height: 14px;
    color: rgba(62, 65, 77, 0.6);
  }

  &__info-value {
    margin: 0;
    font-family: inherit;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.02em;
    color: #3e414d;
  }

  &__block-row {
    display: flex;
    justify-content: space-between;
  }
}
