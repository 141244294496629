.loader {
  &__svg {
    width: 100%;
    margin: 0 auto;
  }

  &__rect {
    fill: #ff6d6d;
  }
}
