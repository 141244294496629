/* Relative */
@font-face {
    font-family: 'Relative';
    src: url('Relative-Medium.eot');
    src: local('Relative Medium'), local('Relative-Medium'),
        url('Relative-Medium.eot?#iefix') format('embedded-opentype'),
        url('Relative-Medium.woff2') format('woff2'),
        url('Relative-Medium.woff') format('woff'),
        url('Relative-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Relative';
    src: url('Relative-BoldItalic.eot');
    src: local('Relative Bold Italic'), local('Relative-BoldItalic'),
        url('Relative-BoldItalic.eot?#iefix') format('embedded-opentype'),
        url('Relative-BoldItalic.woff2') format('woff2'),
        url('Relative-BoldItalic.woff') format('woff'),
        url('Relative-BoldItalic.ttf') format('truetype');
    font-weight: bold;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Relative';
    src: url('Relative-Book.eot');
    src: local('Relative Book'), local('Relative-Book'),
        url('Relative-Book.eot?#iefix') format('embedded-opentype'),
        url('Relative-Book.woff2') format('woff2'),
        url('Relative-Book.woff') format('woff'),
        url('Relative-Book.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'Relative';
    src: url('Relative-BookItalic.eot');
    src: local('Relative Book Italic'), local('Relative-BookItalic'),
        url('Relative-BookItalic.eot?#iefix') format('embedded-opentype'),
        url('Relative-BookItalic.woff2') format('woff2'),
        url('Relative-BookItalic.woff') format('woff'),
        url('Relative-BookItalic.ttf') format('truetype');
    font-weight: normal;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Relative';
    src: url('Relative-MediumItalic.eot');
    src: local('Relative Medium Italic'), local('Relative-MediumItalic'),
        url('Relative-MediumItalic.eot?#iefix') format('embedded-opentype'),
        url('Relative-MediumItalic.woff2') format('woff2'),
        url('Relative-MediumItalic.woff') format('woff'),
        url('Relative-MediumItalic.ttf') format('truetype');
    font-weight: 500;
    font-style: italic;
    font-display: swap;
}

@font-face {
    font-family: 'Relative';
    src: url('Relative-Bold.eot');
    src: local('Relative Bold'), local('Relative-Bold'),
        url('Relative-Bold.eot?#iefix') format('embedded-opentype'),
        url('Relative-Bold.woff2') format('woff2'),
        url('Relative-Bold.woff') format('woff'),
        url('Relative-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

/* TRY Vesterbro */
@font-face {
    font-family: 'TRY Vesterbro';
    src: url('TRYVesterbro-Extrabold.eot');
    src: local('TRY Vesterbro Extrabold'), local('TRYVesterbro-Extrabold'),
        url('TRYVesterbro-Extrabold.eot?#iefix') format('embedded-opentype'),
        url('TRYVesterbro-Extrabold.woff2') format('woff2'),
        url('TRYVesterbro-Extrabold.woff') format('woff'),
        url('TRYVesterbro-Extrabold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'TRY Vesterbro';
    src: url('TRYVesterbro-Light.eot');
    src: local('TRY Vesterbro Light'), local('TRYVesterbro-Light'),
        url('TRYVesterbro-Light.eot?#iefix') format('embedded-opentype'),
        url('TRYVesterbro-Light.woff2') format('woff2'),
        url('TRYVesterbro-Light.woff') format('woff'),
        url('TRYVesterbro-Light.ttf') format('truetype');
    font-weight: 300;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'TRY Vesterbro';
    src: url('TRYVesterbro-Poster.eot');
    src: local('TRY Vesterbro Poster'), local('TRYVesterbro-Poster'),
        url('TRYVesterbro-Poster.eot?#iefix') format('embedded-opentype'),
        url('TRYVesterbro-Poster.woff2') format('woff2'),
        url('TRYVesterbro-Poster.woff') format('woff'),
        url('TRYVesterbro-Poster.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'TRY Vesterbro';
    src: url('TRYVesterbro-Bold.eot');
    src: local('TRY Vesterbro Bold'), local('TRYVesterbro-Bold'),
        url('TRYVesterbro-Bold.eot?#iefix') format('embedded-opentype'),
        url('TRYVesterbro-Bold.woff2') format('woff2'),
        url('TRYVesterbro-Bold.woff') format('woff'),
        url('TRYVesterbro-Bold.ttf') format('truetype');
    font-weight: bold;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'TRY Vesterbro';
    src: url('TRYVesterbro-Medium.eot');
    src: local('TRY Vesterbro Medium'), local('TRYVesterbro-Medium'),
        url('TRYVesterbro-Medium.eot?#iefix') format('embedded-opentype'),
        url('TRYVesterbro-Medium.woff2') format('woff2'),
        url('TRYVesterbro-Medium.woff') format('woff'),
        url('TRYVesterbro-Medium.ttf') format('truetype');
    font-weight: 500;
    font-style: normal;
    font-display: swap;
}

@font-face {
    font-family: 'TRY Vesterbro';
    src: url('TRYVesterbro-Regular.eot');
    src: local('TRY Vesterbro Regular'), local('TRYVesterbro-Regular'),
        url('TRYVesterbro-Regular.eot?#iefix') format('embedded-opentype'),
        url('TRYVesterbro-Regular.woff2') format('woff2'),
        url('TRYVesterbro-Regular.woff') format('woff'),
        url('TRYVesterbro-Regular.ttf') format('truetype');
    font-weight: normal;
    font-style: normal;
    font-display: swap;
}
