@import "assets/styles/main.scss";

.modal-new-pass {
  &__header {
    display: flex;
    justify-content: space-between;
  }

  &__head {
  }

  &__title {
    margin: 0;
    flex: 0 0 auto;
    font-family: inherit;
    font-style: normal;
    font-weight: 500;
    font-size: 20px;
    line-height: 24px;
    letter-spacing: 0.01em;
    color: #3e414d;
  }

  &__text {
    margin: 12px 0 0;
    font-family: inherit;
    font-style: normal;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter-spacing: 0.02em;
    color: rgba(62, 65, 77, 0.6);
  }

  &__form {
    display: flex;
    flex-direction: column;
  }

  &__form-wrapper {
    margin-top: 16px;
    display: flex;
    flex-direction: column;
    margin-bottom: 28px;
    gap: 12px;
  }

  &__input {
    margin-bottom: 12px;

    & > div {
      width: 100%;
    }

    &:last-child {
      margin-bottom: 0;
    }
  }

  &__btn-wrapper {
    align-self: flex-end;
  }
}
